<template>
<div>
  <form @submit.prevent="lookup" class="mb-3">
    <div class="row">
      <!-- add country selection -->
      <div class="col-md-9">
        <phone-form-component v-model="number" @change="checkNumber"/>
      </div>
      <div class="col-md-3">
        <a-button class="btn btn-primary text-nowrap" style="width: 100%" :loading="loading">
          Søk
        </a-button>
        </div>
    </div>
  </form>
  <p>{{ response }}</p>
  <p style="color: red" v-if="error">{{ error }}</p>
  <div class="stats-section mt-3" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
      <h3>Lookup Statistics</h3>
      <div class="btn-group">
        <a-button @click="fetchStats('today')" :class="{ active: selectedRange === 'today' }">Today</a-button>
        <a-button @click="fetchStats('week')" :class="{ active: selectedRange === 'week' }">This Week</a-button>
        <a-button @click="fetchStats('month')" :class="{ active: selectedRange === 'month' }">This Month</a-button>
      </div>
      <a-table :dataSource="stats" :columns="col" class="mt-3"></a-table>
      <!-- <ul>
        <li v-for="user in stats" :key="user.userId">
          {{ user.fullName }} - {{ user.count }} lookups
        </li>
      </ul> -->
    </div>
</div>
</template>

<script>
import { functions, firestore } from '@/services/firebase'
import phoneFormComponent from '@/components/custom/forms/formComponents/telephone'

const col = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Searches',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
  },
]

export default {
  components: {
    phoneFormComponent,
  },
  data() {
    return {
      loading: false,
      number: '',
      response: '',
      error: '',
      stats: [],
      selectedRange: 'today',
      col,
    }
  },
  methods: {
    async lookup() {
      this.loading = true
      this.error = ''
      this.response = ''
      const phone = this.number.substring(1)

      try {
        const carrierLookup = functions.httpsCallable('carrierLookup')
        const res = await carrierLookup({ phone })
        this.response = res.data.carrierName
      } catch (err) {
        this.error = err.message || 'Failed to fetch carrier info.'
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    checkNumber() {
      this.number = this.number.trim().replace(/\D/g, '')
    },
    async fetchStats(range) {
      this.selectedRange = range
      const lookupCollection = firestore.collection('carrierLookup')
      const usersCollection = firestore.collection('users')

      const startDate = new Date()
      startDate.setHours(0, 0, 0, 0)

      if (range === 'week') {
        startDate.setDate(startDate.getDate() - 7)
      } else if (range === 'month') {
        startDate.setMonth(startDate.getMonth() - 1)
      }

      const startDateStr = startDate.toISOString().split('T')[0]

      try {
        const usersSnapshot = await lookupCollection.get()
        const stats = []

        for (const userDoc of usersSnapshot.docs) {
          const userId = userDoc.id
          const historyCollection = userDoc.ref.collection('history')
          const historyQuery = historyCollection.where('__name__', '>=', startDateStr)
          const historySnapshot = await historyQuery.get()

          let totalLookups = 0
          historySnapshot.forEach((doc) => {
            totalLookups += doc.data().count || 0
          })

          if (totalLookups > 0) {
            // Fetch user details
            const userRef = usersCollection.doc(userId)
            const userSnap = await userRef.get()
            const fullName = userSnap.data().fullName
            const cost = String(Math.round((totalLookups * 0.006) * 1000) / 1000) + ' $'

            stats.push({ key: userId, fullName, count: totalLookups, cost })
          }
        }

        this.stats = stats
      } catch (err) {
        console.error('Error fetching stats:', err)
      }
    },
  },
  mounted() {
    if (['underDevelopment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      this.fetchStats('today')
    }
  },
}
</script>

<style>

</style>
